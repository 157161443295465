import PropTypes from 'prop-types';
import React from 'react';

import Banner from '@ui/Banner';

const StagingBanner = ({ isStage }) => {
  return !isStage ? null : <Banner data-testid="staging-notif">This is your staging environment.</Banner>;
};

StagingBanner.propTypes = {
  isStage: PropTypes.bool,
};

export default StagingBanner;
