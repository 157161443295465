import PropTypes from 'prop-types';
import React from 'react';

import Banner from '@ui/Banner';

const SuggestedEditsPreview = ({ isPreview }) => {
  return !isPreview ? null : (
    <Banner data-testid="suggested-edits-preview">You are previewing a suggested edit.</Banner>
  );
};

SuggestedEditsPreview.propTypes = {
  isPreview: PropTypes.bool,
};

export default SuggestedEditsPreview;
