import React from 'react';
import { useLocation } from 'react-router-dom';

import useProjectPlan from '@core/hooks/useProjectPlan';
import { useProjectStore } from '@core/store';

/**
 * Renders the custom HTML footer on the home page.
 */
export default function CustomHomeFooter() {
  const { pathname, search } = useLocation();
  const [footerHtml, firstPage] = useProjectStore(s => [
    s.data.appearance.custom_code.html.home_footer,
    s.data.appearance.navigation.first_page,
  ]);
  const { planFeatures } = useProjectPlan();

  const query = new URLSearchParams(search);
  const disableCustom = query.get('disableCustom') === 'true';

  const isLandingPage = ['/', ''].includes(pathname) && firstPage === 'landing_page';
  const hasAccess = planFeatures.whitelabel;

  return isLandingPage && hasAccess && footerHtml && !disableCustom ? (
    <footer dangerouslySetInnerHTML={{ __html: footerHtml }} />
  ) : null;
}
