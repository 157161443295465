import PropTypes from 'prop-types';
import React from 'react';

import Banner from '@ui/Banner';

const StagingDiscussionsBanner = ({ isDiscuss, isStage }) => {
  if (!isStage || !isDiscuss) return null;
  return (
    <Banner>
      Discussions and comments are live data from your production environment. Changes you make here will go live
      immediately.
    </Banner>
  );
};

StagingDiscussionsBanner.propTypes = {
  isDiscuss: PropTypes.bool,
  isStage: PropTypes.bool,
};

export default StagingDiscussionsBanner;
