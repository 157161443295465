import PropTypes from 'prop-types';
import React from 'react';

import { PLAN_UPGRADE_URL } from '@core/constants/urls';

import Banner from '@ui/Banner';

const InactiveBanner = ({ isActive }) => {
  return isActive ? null : (
    <Banner data-testid="inactive-banner" icon="icon-alert-triangle">
      This site is only viewable by admins.
      <a href={PLAN_UPGRADE_URL}>Launch it to make it public.</a>
    </Banner>
  );
};

InactiveBanner.propTypes = {
  isActive: PropTypes.bool,
};

export default InactiveBanner;
